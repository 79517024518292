import reportWebVitals from "./reportWebVitals";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import "aos/dist/aos.css";
import "./index.css";

import { Spinner } from "./components/Spinner.jsx";
const App = React.lazy(() => import("./App.js"));
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Suspense fallback={<Spinner />}>
      <App />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
